export const navigationPaths = {
  home: {
    app: '/',
    baseUrl: 'https://dashboard.astro.tax',
  },
  mainMenu: {
    home: '/',
    todo: '/todo',
    advies: '/advies',
    cijfers: '/cijfers',
    fiscalProfile: '/fiscaal-profiel',
    myProfile: '/mijn-profiel',
    adminPanel: '/admin-panel',
    makeNewInvoice: '/verkoop/nieuw',
    verkoop: '/verkoop',
    invoiceSettings: '/verkoop/instellingen',
    manageClients: '/klanten-beheren',
    documentUpload: '/documenten-aanleveren',
    documentOverview: '/documenten-kluis',
    missingDocuments: '/ontbrekende-documenten',
    expenseReport: '/onkostennotas',
    makeNewExpenseReport: '/onkostennotas/nieuw',
    transactions: '/transacties',
  },
  help: {
    adviesDatabase: '/advies-database',
    helpCenter: 'https://help.astro.tax/nl/',
  },
  public: {
    login: '/login',
    signUp: '/welkom',
    forgotPassword: '/wachtwoord-vergeten',
    userCallback: '/callback/auth-handler',
    newPassword: '/nieuw-wachtwoord',
    klantWorden: 'https://www.astro.tax/offerte',
    klantWordenDemo: 'https://develop.astro.tax/offerte',

    termsOfAgreement: 'https://www.astro.tax/legal/algemene-voorwaarden',
    deprecatedPublicOffer: '/public/offerte-aanvragen',
  },
  callback: {
    verifyEmail: '/email-verifieren',
  },
  error: {
    somethingWentWrong: '/404',
    serverError: '/500',
  },
  intake: {
    bookAppointment: '/intake/gesprek',
    discussOfferAppointmentCalendlyUrl: 'https://calendly.com/astro-accounting/offerte-bespreken',
    intakeCallCalendlyUrl: 'https://calendly.com/astro-accounting/richt-je-onderneming-op',
    // intakeCallCalendlyUrl:
    // "https://calendly.com/astro-accounting/intakegesprek",
    requestOfferWebsite: 'astro.tax/offerte',
    requestOffer: '/intake/offerte/aanvragen',
    followUpOffer: '/intake/offerte/opvolgen',
    onboardingOverview: '/intake/onboarding',
    intakeForm: '/intake/onboarding/formulier',
    mandate: '/intake/onboarding/mandate',
    ponto: '/intake/onboarding/ponto',
    proxyContract: '/intake/onboarding/volmachten',
    activation: '/intake/onboarding/activatie',
    companySetup: '/intake/oprichting',
    companySetupForm: '/intake/oprichting/formulier',
    companySetupInfo: '/intake/oprichting/info',
    companySetupMeeting: '/intake/oprichting/gesprek',
    companySetupBankAccountInfo: '/intake/oprichting/bank-account-info',
  },
  aangiften: {
    personalTaxOverview: '/aangiften/personenbelasting',
    basicInfo: '/aangiften/personenbelasting/basis-informatie',
    familyInfo: '/aangiften/personenbelasting/gezinssituatie',
    realEstateInfo: '/aangiften/personenbelasting/onroerend-goed',
    fichesInfo: '/aangiften/personenbelasting/attesten',
  },
};

export const userMenu = {
  profile: {
    name: 'Mijn profiel',
    path: navigationPaths.mainMenu.myProfile,
  },
  admin: {
    name: 'Admin-panel',
    path: navigationPaths.mainMenu.adminPanel,
  },
};

export const getIsPublicPath = (path: string) =>
  Object.values(navigationPaths.public).includes(path.split('?')[0]);
